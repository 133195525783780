import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FILTERS_USERS_TABLE, FiltersUsersTableStateType } from '@/features/UsersTable/store/filtersUsersTable/filtersUsersTableTypes'
const initialState: FiltersUsersTableStateType = {
  errors: {},
  filters: {},
  unsavedFilters: {},
}
const filtersUsersTableSlice = createSlice({
  name: FILTERS_USERS_TABLE,
  initialState,
  reducers: {
    // Инициализация стейта для фильтра
    initFilterState: (state, { payload: { id, defaultValue } }: PayloadAction<{ id: string; defaultValue: any }>) => {
      const newFilter = { value: [...defaultValue] }
      state.filters = { ...state.filters, [id]: newFilter }
      state.unsavedFilters = { ...state.unsavedFilters, [id]: newFilter }
    },
    // Инициализация нового фильтра
    setFilterValues: (
      state,
      {
        payload: { id, value, pickAll, showEmpty, ignoreList, search },
      }: PayloadAction<{ id: string; value: any; pickAll?: boolean; showEmpty?:boolean; ignoreList?: any; search?: string }>
    ) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      if (id !== 'globalFilter') {
        if (value) {
          if (pickAll)
            state.unsavedFilters = {
              ...state.unsavedFilters,
              [id]: { value: value, pickAll: pickAll, ignoreList: ignoreList, search: search },
            }
          else state.unsavedFilters = { ...state.unsavedFilters, [id]: { value: value, ignoreList: ignoreList } }
        }
      } else {
        if (value) {
          state.filters = { ...state.filters, [id]: { value: value } }
        }
      }
    },
    setShowEmptyFilter: (state, { payload: { id, showEmpty } }: PayloadAction<{ id: string; showEmpty: boolean }>) => {
      if (id in state.filters) {
        state.unsavedFilters = {
          ...state.unsavedFilters,
          [id]: { ...state.unsavedFilters[id], showEmpty },
        };
      } else {
        throw new Error(`Filter - ${id} is not defined`);
      }
    },
    updateFilterValues: (state, { payload: { id, value } }: PayloadAction<{ id: string; value: any }>) => {
      if (value) {
        state.unsavedFilters = {
          ...state.unsavedFilters,
          [id]: { ...state.unsavedFilters[id], value: [...state.unsavedFilters[id].value, value] },
        }
      }
    },
    updateIgnoreListFilterValues: (state, { payload: { id, value } }: PayloadAction<{ id: string; value: any }>) => {
      if (value) {
        state.unsavedFilters = {
          ...state.unsavedFilters,
          [id]: { ...state.unsavedFilters[id], ignoreList: [...state.unsavedFilters[id].ignoreList, value] },
        }
      }
    },
    removeFilterValues: (state, { payload: { id, valueToRemove } }: PayloadAction<{ id: string; valueToRemove: any }>) => {
      if (valueToRemove) {
        state.unsavedFilters = {
          ...state.unsavedFilters,
          [id]: {
            ...state.unsavedFilters[id],
            value: [...state.unsavedFilters[id].value.filter((filterValue: any) => filterValue.id !== valueToRemove.id)],
          },
        }
      }
    },
    removeIgnoreListFilterValues: (state, { payload: { id, valueToRemove } }: PayloadAction<{ id: string; valueToRemove: any }>) => {
      if (valueToRemove) {
        state.unsavedFilters = {
          ...state.unsavedFilters,
          [id]: {
            ...state.unsavedFilters[id],
            ignoreList: [...state.unsavedFilters[id].ignoreList.filter((ignoreListValue: any) => ignoreListValue.id !== valueToRemove.id)],
          },
        }
      }
    },
    setRestoredOptions: (state, { payload: filterData }: PayloadAction<{ id: string; data: any }[]>) => {
      Object.entries(state.filters).forEach(([key, filter]: any) => {
        const restoredFilterData = filterData.find((item) => item.id === key)
        if (restoredFilterData) {
          filter.value = [...restoredFilterData.data.value]
          if (restoredFilterData.data.pickAll) filter.pickAll = restoredFilterData.data.pickAll
          if (restoredFilterData.data.showEmpty) filter.showEmpty = restoredFilterData.data.showEmpty
          if (restoredFilterData?.data?.ignoreList?.length) filter.ignoreList = [...restoredFilterData.data.ignoreList]
          if (restoredFilterData?.data?.search) filter.search = restoredFilterData.data.search
        }
      })
      Object.entries(state.unsavedFilters).forEach(([key, filter]: any) => {
        const restoredFilterData = filterData.find((item) => item.id === key)
        if (restoredFilterData) {
          filter.value = [...restoredFilterData.data.value]
          if (restoredFilterData.data.pickAll) filter.pickAll = restoredFilterData.data.pickAll
          if (restoredFilterData.data.showEmpty) filter.showEmpty = restoredFilterData.data.showEmpty
          if (restoredFilterData?.data?.ignoreList?.length) filter.ignoreList = [...restoredFilterData.data.ignoreList]
          if (restoredFilterData?.data?.search) filter.search = restoredFilterData.data.search
        }
      })
    },
    submitFilters: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.filters = { ...state.filters, [id]: { ...state.unsavedFilters[id] } }
    },

    resetFiltersSlice: () => initialState,
  },
})
export default filtersUsersTableSlice.reducer

export const {
  initFilterState,
  setFilterValues,
  setRestoredOptions,
  resetFiltersSlice,
  submitFilters,
  updateFilterValues,
  updateIgnoreListFilterValues,
  removeFilterValues,
  removeIgnoreListFilterValues,
  setShowEmptyFilter
} = filtersUsersTableSlice.actions
